import { RecipientGuardService } from './Services/guard/recipient/recipient-guard.service';
import { RecipientListComponent } from './Components/recipient-list/recipient-list.component';
import { TemplateGuardService } from './Services/guard/template/template-guard.service';
import { MessageGuardService } from './Services/guard/message/message-guard.service';
import { TemplateComponent } from './Components/template/template.component';
import { BrowserComponent } from './Components/browser/browser.component';
import { UnauthorizedUserComponent } from './Components/unauthorized-user/unauthorized-user.component';
import { GuardService } from './Services/guard/guard.service';
import { MessageDetailComponent } from './Components/message-detail/message-detail.component';
import { NotificationComponent } from './Components/notification/notification/notification.component';
import { PostmanMessagesComponent } from './Components/postman-messages/postman-messages.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AccordionModule } from 'primeng/accordion';     // accordion and accordion tab
import { MenuItem, MessageService } from 'primeng/api';                  // api

import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { InputNumberModule } from 'primeng/inputnumber';

import { CookieService } from 'ngx-cookie-service';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';

enableProdMode();

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import { detect } from 'detect-browser';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    PostmanMessagesComponent,
    NotificationComponent,
    MessageDetailComponent,
    UnauthorizedUserComponent,
    BrowserComponent,
    TemplateComponent,
    RecipientListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    AccordionModule,
    PanelModule,
    ButtonModule,
    RadioButtonModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    MenubarModule,
    InputTextModule,
    ProgressSpinnerModule,
    PickListModule,
    CalendarModule,
    MultiSelectModule,
    DialogModule,
    ScrollPanelModule,
    ToastModule,
    CardModule,
    MenuModule,
    InputNumberModule,
    TabViewModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
       { path: 'messages', component: PostmanMessagesComponent, canActivate: [ MessageGuardService ] },
       // { path: '', redirectTo: 'messages', pathMatch: 'full' },
       { path: 'messages/:id', component: PostmanMessagesComponent, canActivate: [ MessageGuardService ] },
       { path: 'messagedetail/:id', component: MessageDetailComponent, canActivate: [ MessageGuardService ] },
       { path: 'templates', component: TemplateComponent, canActivate: [ TemplateGuardService ] },
       { path: 'recipientlist', component: RecipientListComponent, canActivate: [ RecipientGuardService ] },
       { path: 'unauthorizeduser', component: UnauthorizedUserComponent },
       { path: 'xxxxx', component: BrowserComponent }
      ])
  ],
  providers: [
    CookieService,
    ConfirmationService,
    MessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  //  bootstrap: [
  //     AppComponent
  //  ]
   entryComponents: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    ) {
    localStorage.setItem('oi', '');
    localStorage.setItem('ui', '');
  }

  ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    keycloakService
      .init(
        {
          config: {
            url: environment.keycloakUrl + '/auth',
            realm: environment.realm,
            clientId: environment.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: [
              '/assets',
              '/clients/public']
        })
      .then(async () => {
        // console.log('[ngDoBootstrap] bootstrap app')

        // console.log((await keycloakService.loadUserProfile()).email)

        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production });
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
  }
}

import { HttpClient } from '@angular/common/http';
import { UsersService } from './Services/users/users.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { GroupService } from './Services/group/group.service';
import { PermissionService } from './Services/permission/permission.service';
import { Variables } from './Models/variables';
import { KeycloakService } from 'keycloak-angular';
import { FileService } from './Services/file/file.service';
import { LanguageService } from './Services/language/language.service';
import { DataCache } from './Helpers/data-cache';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'GOEPLA-UI-Postman';
  load = false;

  loading = false;

  groupids: any;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  infoText = 'Information';
  closeText = 'Close';

  items: MenuItem[] = [];

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private usersService: UsersService,
    private router: Router,
    private groupService: GroupService,
    private userService: UsersService,
    private permService: PermissionService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    if ('serviceWorker' in navigator) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    localStorage.removeItem('selected');
    localStorage.removeItem('filter');
    router.navigateByUrl('');
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.load = true;
    this.loading = true;
    this.showLoad();
    }

  async ngOnInit() {
    this.load = true;

    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
  }

  async setIcon() {
    this.loading = true;
    this.showLoad();
    const file = await this.fileService.getFiles(1, 1, ' ', 'postman-icon', 'postman-bucket', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('postman-bucket', file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
    this.hideLoad();
  }

  async getUser() {
    this.showLoad();
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    const ress = await this.userService.getUserInfo(0, 0, eml, null).toPromise().catch(err => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.showInfo('null');
      await this.getGroups();
      this.cacheData();
    } else {
      this.hideLoad();
      this.loading = false;
      this.load = false;
      await this.showInfo('user');
    }
  }

  async getGroups() {
    this.showLoad();
    this.loading = true;
    const v = new Variables();
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const uid = +ui;
    let mygroups = '';
    let username = '';
    let groupids = '';
    const gis = [];
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        gis.push(val.groupId);
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const gds = JSON.stringify(gis);
    const gids = CryptoJS.AES.encrypt(gds, v.pass);
    localStorage.setItem('gi', gids);

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    this.groupids = groupids;

    await this.getPermissions(user, mygroups);

    this.loading = false;
    // this.hideLoad();
  }

  async getPermissions(org, mygroups) {
    this.showLoad();
    this.loading = true;
    const permissions = [];
    const groupids = this.groupids;
    if (groupids !== undefined && groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('POSTMAN.Administration.Full');
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);

    await this.buildMenu(permissions);
    this.loading = false;
    // this.hideLoad();
  }

  async buildMenu(perm) {
    if (perm != null && perm.length > 0) {
      let isuser = false;
      if (perm.includes('POSTMAN.Section.Messages') || perm.includes('POSTMAN.Administration.Full')) {
        this.translate.get('GENERAL.MESSAGES').subscribe((resp: string) => {
          this.items.push({ label: resp, id: 'messagesmenu', routerLink: ['/messages'] });
        });
        this.router.navigateByUrl('/messages');
        isuser = true;
      }
      if (perm.includes('POSTMAN.Section.Templates') || perm.includes('POSTMAN.Administration.Full')) {
        this.translate.get('GENERAL.TEMPLATES').subscribe((resp: string) => {
          this.items.push({ label: resp, id: 'templatesmenu', routerLink: ['/templates'] });
        });
        isuser = true;
      }
      if (perm.includes('POSTMAN.Section.RecipientList') || perm.includes('POSTMAN.Administration.Full')) {
        this.translate.get('GENERAL.RECIPIENTLIST').subscribe((resp: string) => {
          this.items.push({ label: resp, id: 'recipientlistmenu', routerLink: ['/recipientlist'] });
        });
        isuser = true;
      }

      if (isuser === false) {
        this.load = false;
        this.router.navigateByUrl('/unauthorizeduser');
      }
    } else {
      this.load = false;
      this.router.navigateByUrl('/unauthorizeduser');
    }
  }

  async showInfo(info) {
    this.showLoad();
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        // this.confirm('Browser doesn\'t fulfill the browser requirements:' +
        // ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('message', 'Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      } else if (info === 'user') {
        // this.confirm('USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('message', 'USER NOT FOUND. User can\'t continue using the application !!!!!');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      }
      this.loading = false;
      this.hideLoad();
      return;
    } else {
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        }

        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

        this.translate.get('GENERAL.CLOSE').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      } else {
        if (info === 'browser') {
          this.load = false;
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  cacheData() {
    DataCache.loadData(this.http);
    // 500000 milliseconds = 8.33333333 mins
    // tslint:disable-next-line: only-arrow-functions
    setInterval(() => {
      DataCache.loadData(this.http);
    }, 500000);
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo2(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.hideLoad();
    console.log(err);
  }

}

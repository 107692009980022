import { KeyValue, Keyvalue, StringUpdater } from 'src/app/Models/keyvalue';
import { Template } from './../../Models/template';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { Variables } from 'src/app/Models/variables';
import { TemplateService } from 'src/app/Services/template/template.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DataCache } from 'src/app/Helpers/data-cache';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  innerdiv = '';
  innerdiv_c = '';
  innerinnerdiv = '';
  innerinnerdivin = '';
  contentdiv = '';
  master = 'x';
  detail = 'y';

  isdetail = false;

  templates = [];
  template: any;

  iscreate = false;
  isedit = false;

  ismanage = false;

  isnotemplates = false;

  tloadmore = false;

  page = 1;
  size = 100;

  templatevalue: any;

  tempname: string;
  tempvalue: string;

  templatenameerror = false;
  templatevalueerror = false;

  adgroups1 = [];
  adgroups2 = [];

  gs = [];
  gps = [];

  roles = [];

  isadm = false;
  iseditable = false;

  ishide = false;
  isshow = false;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private tempService: TemplateService,
    private domSanitizer: DomSanitizer
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.onResize(null);

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p == null) {
      this.ismanage = false;
    }
    const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(val);
    if (perm.includes('POSTMAN.Templates.Manage') || perm.includes('POSTMAN.Administration.Full')) {
      this.ismanage = true;
    } else {
      this.ismanage = false;
    }

    if (perm.includes('POSTMAN.Administration.Full')) {
      this.isadm = true;
    }
  }

  async ngOnInit() {
    await this.getTemplates();
    this.getGroups3();
  }

  getGroups() {
    this.showLoad();
    // tslint:disable-next-line: deprecation
    DataCache.groupdata$.subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.adgroups1.push(res[i]);
        }
      }
      this.hideLoad();
    });
  }

  async getGroups2() {
    this.showLoad();
    this.gs = [];
    this.gps = [];
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.gs.push(res[i]);
      }
      this.arrangeRoles();
    }
    this.hideLoad();
  }

  async getGroups3() {
    this.showLoad();
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.gps.push(res[i]);
      }
    }
    this.hideLoad();
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.template.typeRoleGroups !== null && this.template.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ad = true;
          for (const val of this.template.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.adgroups1.push(val);
        }
      }
    }
  }

  async getTemplates() {
    this.showLoad();

    const temps = await this.tempService.getTemplates(this.page, this.size, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (temps !== undefined && temps !== null && temps.length > 0) {
      const ts = [];
      if (temps.length >= this.size) {
        this.tloadmore = true;
      } else {
        this.tloadmore = false;
      }

      for (const val of temps) {
        val.color = 'white';
        if (this.page > 1) {
          this.templates.push(val);
        } else {
          ts.push(val);
        }
      }

      if (this.page === 1) {
        this.templates = ts;
      }
    } else {
      this.tloadmore = false;
      if (this.templates.length === 0) {
        this.isnotemplates = true;
      } else {
        this.isnotemplates = false;
      }
    }

    this.hideLoad();
  }

  async loadMoreT() {
    this.page += 1;
    await this.getTemplates();
  }

  async templateClicked(templ) {
    this.ishide = false;
    this.isshow = true;

    this.cancel();
    this.templatevalue = this.domSanitizer.bypassSecurityTrustHtml(templ.templateValue);
    this.template = templ;
    this.isdetail = true;

    const ts = [];
    for (const val of this.templates) {
      const t: any = [];
      t.id = val.id;
      t.templateName = val.templateName;
      t.templateValue = val.templateValue;
      t.typeRoleGroups = val.typeRoleGroups;
      t.roleGroups = val.roleGroups;

      if (val.id === templ.id) {
        t.color = 'LightBlue';
      } else {
        t.color = 'White';
      }
      ts.push(t);
    }
    this.templates = ts;
    this.master = 'w';
    this.detail = 'z';

    this.iseditable = false;
    this.roles = [];

    if (templ.typeRoleGroups !== null && templ.typeRoleGroups.length > 0) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      await this.getGroups3();

      for (const val of templ.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          this.iseditable = true;
        }

        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
              val.typeRoleGroup.color = 'white';
            }
          }
        }

        this.roles.push(val.typeRoleGroup);
      }
    }

    if (this.isadm === true) {
      this.iseditable = true;
    }

    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }


  roleClicked(role: any): any {
    const rls = [];
    for (const val of this.roles) {
      const mcu: any = [];
      mcu.parentId = val.parentId;
      mcu.groupId = val.groupId;
      mcu.g = val.g;
      mcu.roleName = val.roleName;
      mcu.roleParentType = val.roleParentType;
      if (role.groupId === val.groupId && role.roleName === val.roleName) {
        mcu.color = '#c9e2ee';
      } else {
        mcu.color = 'white';
      }
      rls.push(mcu);
    }
    this.roles = rls;
  }


  showAddTemplate() {
    this.templatenameerror = false;
    this.templatevalueerror = false;
    this.iscreate = true;
    this.isedit = false;
    this.master = 'w';
    this.detail = 'z';
    this.onResize(null);

    this.adgroups1 = [];
    this.adgroups2 = [];
    this.getGroups();
  }

  showEditTemplate() {
    this.templatenameerror = false;
    this.templatevalueerror = false;
    this.isedit = true;
    this.iscreate = false;
    this.tempname = this.template.templateName;
    this.tempvalue = this.template.templateValue;

    this.adgroups1 = [];
    this.adgroups2 = [];
    this.getGroups2();
  }

  cancel() {
    this.tempname = null;
    this.tempvalue = null;
    this.iscreate = false;
    this.isedit = false;

    if (this.template === undefined || this.template === null) {
      this.backClicked();
    }
}

  async showConfirmDeleteTemplate() {
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + this.template.templateName + '?',
    '', 'deletetemplate');
  }

  async deleteTemplate() {
    this.showLoad();

    const res = await this.tempService.deleteTemplate(this.template.id).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.TEMPLATEDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteTemplate();

      this.size = this.page * this.size;
      this.page = 1;
      await this.getTemplates();
      this.size = 100;

      if (this.templates != null && this.templates.length > 0) {
        await this.templateClicked(this.templates[0]);
      }
    }

    this.hideLoad();
  }

  onRejectDeleteTemplate() {
    this.messageService.clear('deletetemplate');
  }

  async create() {
    let iscreate = true;
    this.templatenameerror = false;
    this.templatevalueerror = false;
    if (this.tempname === undefined || this.tempname === null || this.tempname.trim() === '') {
      this.templatenameerror = true;
      iscreate = false;
    }
    if (this.tempvalue === undefined || this.tempvalue === null || this.tempvalue.trim() === '') {
      this.templatevalueerror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      const templ = new Template();
      templ.TemplateName = this.tempname;
      templ.TemplateValue = this.tempvalue;

      const rgroups = [];
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'template';

          rgroups.push(rgroup);
        }
      }

      templ.RoleGroups = rgroups;

      this.showLoad();

      const res = await this.tempService.postTemplate(templ).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res > 0) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.TEMPLATECREATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.page * this.size;
        this.page = 1;
        await this.getTemplates();
        this.size = 100;

        if (this.templates != null && this.templates.length > 0) {
          for (const va of this.templates) {
            if (va.id === res) {
              await this.templateClicked(va);
            }
          }
        }

        this.cancel();
      }

      this.hideLoad();
    } else {
      setTimeout(() => {
        this.onResize(null);
      }, 100);
    }
  }

  async save() {
    let iscreate = true;
    this.templatenameerror = false;
    this.templatevalueerror = false;
    if (this.tempname === undefined || this.tempname === null || this.tempname.trim() === '') {
      this.templatenameerror = true;
      iscreate = false;
    }
    if (this.tempvalue === undefined || this.tempvalue === null || this.tempvalue.trim() === '') {
      this.templatevalueerror = true;
      iscreate = false;
    }
    if (iscreate === true) {
      const value = new StringUpdater();
      value.Id = this.template.templateName;
      value.Values = [];

      const tvalue = new Keyvalue();
      tvalue.Key = 'template_value';
      tvalue.Value = this.tempvalue;
      value.Values.push(tvalue);

      const rgroups = [];
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.ParentId = this.template.id;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'template';

          rgroups.push(rgroup);
        }
      }

      const data = new TypeRoleGroupData();
      data.ParentId = this.template.id;
      data.RoleParentType = 'template';
      data.TypeRoleGroup = rgroups;

      const rg = new KeyValue();
      rg.Key = 'role_groups';
      rg.Value = JSON.stringify(data);
      value.Values.push(rg);

      this.showLoad();

      const res = await this.tempService.putTemplate(value).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res > 0) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.TEMPLATEUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.page * this.size;
        this.page = 1;
        await this.getTemplates();
        this.size = 100;

        if (this.templates != null && this.templates.length > 0) {
          for (const val of this.templates) {
            if (val.id === this.template.id) {
              await this.templateClicked(val);
            }
          }
        }

        this.cancel();
      }

      this.hideLoad();
    } else {
      setTimeout(() => {
        this.onResize(null);
      }, 100);
    }
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  onResize(event) {
    const menuheight = document.getElementById('menu')?.offsetHeight;
    const contentheight = window.innerHeight;

    let cheight = document.getElementById('maindata')?.offsetHeight;
    if (cheight === undefined || cheight === null) {
      cheight = 0;
    }

    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    this.innerdiv = (contentheight - 138) + 'px';
    this.innerdiv_c = (contentheight - 130) + 'px';

    this.innerinnerdiv = '';

    if (this.ishide === true) {
      this.innerinnerdivin = '30px';
      this.innerinnerdiv = (contentheight - 160 - 30) + 'px';
    } else {
      this.innerinnerdiv = ((contentheight - 185) / 2 ) + 'px';
      this.innerinnerdivin = ((contentheight - 220) / 2 ) + 'px';
    }
  }

  async hideData() {
    this.ishide = true;
    this.isshow = false;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  showData() {
    this.ishide = false;
    this.isshow = true;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  async handleError(err) {
    console.log(err)
    this.hideLoad();
    if (err !== undefined && err !== null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText === 'duplicate key value violates unique constraint "t_template_pkey"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise();
          const msg = await this.translate.get('GENERAL.TEMPLATEALREADYEXISTS').toPromise();
          this.showWarn(title, msg, 10000);
        } else {
          console.log(err);
        }
      } else {
        console.log(err);
      }
    }
  }

}

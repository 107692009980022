export class Keyvalue {
    public Key: string;
    public Value: string;
}

export class StringUpdater {
    public Id: string;
    public Values: Keyvalue[];
}

export class Int64Updater {
    public Id: number;
    public Values: Keyvalue[];
}

export class KeyValue {
    public Key: string;
    public Value: string;
}

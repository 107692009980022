import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PostmanMessageService {
  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

      this.orgId = +oi;
  }

  getMessages(page: number, size: number, Id: number, source: string, channel: string,
              start: string, end: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

    this.orgId = +oi;
    const url = this.apiUrl + '/api/messages/' + page + '/' + size + '/' + this.orgId + '/' + Id + '/'
               + source + '/' + channel + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getMessageById(id: string) {
    const url = this.apiUrl + '/api/messages/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
    tap( // Log the result or error
    error => this.handleError
    )
    );
    }

  private handleError(error: any) {
    console.error('error', error);
    return error;
  }
}

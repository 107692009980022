import { Recipients, RecipientUpdater, TempRecipients } from './../../Models/recipients';
import { RecipientService } from './../../Services/recipient/recipient.service';
import { Int64Updater, KeyValue, Keyvalue } from 'src/app/Models/keyvalue';
import { RecipientList } from './../../Models/recipient-list';
import { RecipientListService } from './../../Services/recipient-list/recipient-list.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { DataCache } from 'src/app/Helpers/data-cache';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';

@Component({
  selector: 'app-recipient-list',
  templateUrl: './recipient-list.component.html',
  styleUrls: ['./recipient-list.component.css']
})
export class RecipientListComponent implements OnInit {

  contentdiv = '';
  innerdiv = '';
  innerinnerdiv = '';
  pickerdiv = '';
  outerdiv = '';

  master = 'x';
  detail = 'y';

  isdetail = false;

  rlists = [];
  rlist: any;

  iscreate = false;
  isedit = false;

  ismanage = false;

  isnolist = false;

  rloadmore = false;

  page = 1;
  size = 100;

  listname: string;
  listdesc: string;

  listnameerror = false;
  listdescerror = false;

  recipients = [];
  recp: any;
  isnorecipients = false;

  isaddrecipient = false;
  iseditrecipient = false;

  rtypes = [];

  recipt: any;
  rcomment: any;
  rtype: any;

  recipterror = false;
  rcommenterror = false;
  rtypeerror = false;
  invalidemail = false;
  invalidphone = false;

  adgroups1 = [];
  adgroups2 = [];

  gs = [];
  gps = [];

  roles = [];

  isadm = false;
  iseditable = false;

  ishide = false;
  isshow = false;

  temprecipients = [];

  options = [];

  users = [];
  groups = [];

  tusers = [];
  tgroups = [];

  user: any;
  group: any;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private listService: RecipientListService,
    private domSanitizer: DomSanitizer,
    private recpService: RecipientService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.onResize(null);

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p == null) {
      this.ismanage = false;
    }
    const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(val);
    if (perm.includes('POSTMAN.RecipientList.Manage') || perm.includes('POSTMAN.Administration.Full')) {
      this.ismanage = true;
    } else {
      this.ismanage = false;
    }

    if (perm.includes('POSTMAN.Administration.Full')) {
      this.isadm = true;
    }
  }

  async ngOnInit() {
    const usergroup = await this.translate.get('GENERAL.USERGROUP').toPromise();
    const user = await this.translate.get('GENERAL.USER').toPromise();
    const phone = await this.translate.get('GENERAL.PHONE').toPromise();
    const email = await this.translate.get('GENERAL.EMAIL').toPromise();

    this.rtypes.push({ label: usergroup, value: 'user-group' });
    this.rtypes.push({ label: user, value: 'user' });
    this.rtypes.push({ label: phone, value: 'phone' });
    this.rtypes.push({ label: email, value: 'email' });

    await this.getRecipientList();
    this.getGroups3();
    this.getUsers();
  }

  async getGroups() {
    this.adgroups1 = [];
    this.showLoad();
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.adgroups1.push(res[i]);
      }
    }
    this.hideLoad();
  }

  async getGroups2() {
    this.showLoad();
    this.gs = [];
    this.gps = [];
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.gs.push(res[i]);
      }
      this.arrangeRoles();
    }
    this.hideLoad();
  }

  async getGroups3() {
    this.groups = [];
    this.gps = [];
    this.showLoad();
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.gps.push(res[i]);
        this.groups.push({label: res[i].groupName, value: res[i]});
      }
    }
    this.hideLoad();
  }

  async getUsers() {
    this.users = [];
    this.showLoad();
    // tslint:disable-next-line: deprecation
    const res = await DataCache.userdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        this.users.push({label: res[i].firstName + ' ' + res[i].lastName, value: res[i]});
      }
    }
    this.hideLoad();
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.rlist.typeRoleGroups !== null && this.rlist.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ad = true;
          for (const val of this.rlist.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.adgroups1.push(val);
        }
      }
    }
  }

  arrangeUsers() {
    if (this.users !== null && this.users.length > 0) {
      if (this.temprecipients !== null && this.temprecipients.length > 0) {
        for (const v of this.users) {
          let ad = true;
          for (const val of this.temprecipients) {
            if (val.RecipientType === 'user') {
              if (v.value.userId.toString() === val.Recipient) {
                ad = false;
              }
            }
          }

          if (ad === true) {
            this.tusers.push(v);
          }
        }
      } else {
        for (const val of this.users) {
          this.tusers.push(val);
        }
      }
    }
  }

  arrangeGroups() {
    if (this.groups !== null && this.groups.length > 0) {
      if (this.temprecipients !== null && this.temprecipients.length > 0) {
        for (const v of this.groups) {
          let ad = true;
          for (const val of this.temprecipients) {
            if (val.RecipientType === 'user-group') {
              if (v.value.groupId.toString() === val.Recipient) {
                ad = false;
              }
            }
          }

          if (ad === true) {
            this.tgroups.push(v);
          }
        }
      } else {
        for (const val of this.groups) {
          this.tgroups.push(val);
        }
      }
    }
  }

  async getRecipientList() {
    this.showLoad();

    const ls = await this.listService.getRecipientLists(this.page, this.size, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (ls !== undefined && ls !== null && ls.length > 0) {
      const ts = [];
      if (ls.length >= this.size) {
        this.rloadmore = true;
      } else {
        this.rloadmore = false;
      }

      for (const val of ls) {
        val.color = 'white';
        if (this.page > 1) {
          this.rlists.push(val);
        } else {
          ts.push(val);
        }
      }

      if (this.page === 1) {
        this.rlists = ts;
      }
    } else {
      this.rloadmore = false;
      if (this.rlists.length === 0) {
        this.isnolist = true;
      } else {
        this.isnolist = false;
      }
    }

    this.hideLoad();
  }

  async getRecipients() {
    this.showLoad();
    this.isnorecipients = false;

    const recps = await this.recpService.getRecipients(this.rlist.recipientListId, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));

    if (recps !== undefined && recps !== null && recps.length > 0) {
      const rs = [];
      for (const val of recps) {
        val.color = 'white';

        const options = [];
        const edt = await this.translate.get('GENERAL.EDIT').toPromise();
        const del = await this.translate.get('GENERAL.DELETE').toPromise();
        options.push({label: edt, value: 'edit',
        command: async (event) => { await this.manageRecipient(event, val); }});
        options.push({label: del, value: 'delete',
        command: async (event) => { await this.manageRecipient(event, val); }});

        val.options = options;
        rs.push(val);
      }

      this.recipients = rs;
    } else {
      this.recipients = [];
    }

    if (this.recipients === null || this.recipients.length === 0) {
      this.isnorecipients = true;
    } else {
      this.isnorecipients = false;
    }

    this.hideLoad();
  }

  manageRecipient(event, val) {
    if (event.item.value === 'delete') {
      if (this.iscreate === true || this.isedit === true) {
        this.deleteTempRecipient(val);
        return;
      }
      this.recp = val;
      this.showConfirmDeleteRecipient();
    } else if (event.item.value === 'edit') {
      if (this.iscreate === true || this.isedit === true) {
        this.cancelRecipient();
        this.recp = val;
        this.rtype = val.RecipientType;
        this.recipt = val.Recipient;
        this.rcomment = val.RecipientComment;

        this.rtypeerror = false;
        this.recipterror = false;
        this.rcommenterror = false;
        this.invalidemail = false;
        this.invalidphone = false;

        if (val.RecipientType === 'user') {
          for (const u of this.users) {
            if (u.value.userId.toString() === val.Recipient) {
              this.user = u.value;
            }
          }
          this.arrangeUsers();
        } else if (val.RecipientType === 'user-group') {
          for (const g of this.groups) {
            if (g.value.groupId.toString() === val.Recipient) {
              this.group = g.value;
            }
          }
          this.arrangeGroups();
        }

        this.iseditrecipient = true;

        return;
      }

      this.cancelRecipient();
      this.recp = val;
      this.rtype = val.recipientType;
      this.recipt = val.recipient;
      this.rcomment = val.recipientComment;

      this.rtypeerror = false;
      this.recipterror = false;
      this.rcommenterror = false;
      this.invalidemail = false;
      this.invalidphone = false;

      if (val.recipientType === 'user') {
        for (const u of this.users) {
          if (u.userId === val.recipient) {
            this.user = u;
          }
        }
      } else if (val.recipientType === 'user-group') {
        for (const g of this.groups) {
          if (g.groupId === val.recipient) {
            this.group = g;
          }
        }
      }

      this.iseditrecipient = true;
    }
  }

  async loadMoreR() {
    this.page += 1;
    await this.getRecipientList();
  }

  async listClicked(ls) {
    this.cancel();
    this.rlist = ls;
    this.isdetail = true;

    const ts = [];
    for (const val of this.rlists) {
      const t: any = [];
      t.recipientListId = val.recipientListId;
      t.recipientListName = val.recipientListName;
      t.recipientListDescription = val.recipientListDescription;
      t.typeRoleGroups = val.typeRoleGroups;
      t.roleGroups = val.roleGroups;
      if (val.recipientListId === ls.recipientListId) {
        t.color = 'LightBlue';
      } else {
        t.color = 'White';
      }
      ts.push(t);
    }
    this.rlists = ts;
    this.master = 'w';
    this.detail = 'z';

    setTimeout(() => {
      this.onResize(null);
    }, 100);

    this.iseditable = false;
    this.roles = [];

    if (ls.typeRoleGroups !== null && ls.typeRoleGroups.length > 0) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      await this.getGroups3();

      for (const val of ls.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          this.iseditable = true;
        }

        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
              val.typeRoleGroup.color = 'white';
            }
          }
        }

        this.roles.push(val.typeRoleGroup);
      }
    }

    if (this.isadm === true) {
      this.iseditable = true;
    }

    this.recipients = [];
    await this.getRecipients();
    this.onResize(null);
  }

  recipientClicked(rp) {
    this.recp = rp;

    const nrs = [];
    for (const val of this.recipients) {
      const nrp: any = [];
      nrp.group = val.group;
      nrp.recListDesc = val.recListDesc;
      nrp.recListId = val.recListId;
      nrp.recListName = val.recListName;
      nrp.recipient = val.recipient;
      nrp.recipientComment = val.recipientComment;
      nrp.recipientType = val.recipientType;
      nrp.user = val.user;
      nrp.options = val.options;
      if (val.recipientType === rp.recipientType && val.recipient === rp.recipient) {
        nrp.color = 'lightblue';
      } else {
        nrp.color = 'white';
      }
      nrs.push(nrp);
    }

    this.recipients = nrs;
  }

  tempRecipientClicked(rp) {
    this.recp = rp;

    const nrs = [];
    for (const val of this.temprecipients) {
      const nrp = new Recipients();
      nrp.RecListId = val.recListId;
      nrp.Recipient = val.Recipient;
      nrp.RecipientComment = val.RecipientComment;
      nrp.RecipientType = val.RecipientType;
      // if (val.recipientType === rp.recipientType && val.recipient === rp.recipient) {
      //   nrp.color = 'lightblue';
      // } else {
      //   nrp.color = 'white';
      // }
      nrs.push(nrp);
    }

    this.temprecipients = nrs;
  }

  deleteTempRecipient(rp) {
    this.recp = rp;

    const nrs = [];
    for (const val of this.temprecipients) {
      if (val.RecipientType === rp.RecipientType && val.Recipient === rp.Recipient) {
        //
      } else {
        nrs.push(val);
      }
    }

    this.temprecipients = nrs;
  }

  async showAddRecipientList() {
    this.listnameerror = false;
    this.listdescerror = false;
    this.temprecipients = [];
    this.iscreate = true;
    this.isedit = false;
    this.master = 'w';
    this.detail = 'z';

    this.adgroups1 = [];
    this.adgroups2 = [];
    await this.getGroups();
    await this.getUsers();

    this.arrangeUsers();
    this.arrangeGroups();

    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  async showEditRecipientList() {
    this.listnameerror = false;
    this.listdescerror = false;

    this.adgroups1 = [];
    this.adgroups2 = [];
    await this.getGroups2();
    await this.getUsers();
    this.listname = this.rlist.recipientListName;
    this.listdesc = this.rlist.recipientListDescription;

    this.temprecipients = [];
    if (this.recipients !== undefined && this.recipients !== null && this.recipients.length > 0) {
      const trps = [];
      for (const val of this.recipients) {
        const rp = new TempRecipients();
        rp.RecListId = val.recListId;
        rp.Recipient = val.recipient;
        rp.RecipientComment = val.recipientComment;
        rp.RecipientType = val.recipientType;
        rp.group = val.group;
        rp.user = val.user;

        const options = [];
        const edt = await this.translate.get('GENERAL.EDIT').toPromise();
        const del = await this.translate.get('GENERAL.DELETE').toPromise();
        options.push({label: edt, value: 'edit',
        command: async (event) => { this.manageRecipient(event, rp); }});
        options.push({label: del, value: 'delete',
        command: async (event) => { this.manageRecipient(event, rp); }});

        rp.options = options;
        trps.push(rp);
      }

      this.temprecipients = trps;

      this.arrangeUsers();
      this.arrangeGroups();
    }

    this.iscreate = false;
    this.isedit = true;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  showAddRecipient() {
    this.cancelRecipient();
    this.isaddrecipient = true;
    this.group = null;
    this.user = null;

    this.rtypeerror = false;
    this.recipterror = false;
    this.rcommenterror = false;
    this.invalidemail = false;
    this.invalidphone = false;
  }

  cancelRecipient() {
    this.rtype = null;
    this.recipt = null;
    this.rcomment = null;

    this.isaddrecipient = false;
    this.iseditrecipient = false;
  }

  async setOldRecipient(recp, newr) {

    let ocmnt = false;
    if (this.temprecipients !== null && this.temprecipients.length > 0) {
      for (const tval of this.temprecipients) {
        if (tval.RecipientType === newr.RecipientType && tval.Recipient === newr.Recipient) {
          ocmnt = true;
        }
      }
    }

    const nrs = [];
    for (const val of this.temprecipients) {
      let options = [];
      const edt = await this.translate.get('GENERAL.EDIT').toPromise();
      const del = await this.translate.get('GENERAL.DELETE').toPromise();
      options.push({label: edt, value: 'edit',
      command: async (event) => { this.manageRecipient(event, val); }});
      options.push({label: del, value: 'delete',
      command: async (event) => { this.manageRecipient(event, val); }});

      val.options = options;
      if (val.RecipientType === recp.RecipientType && val.Recipient === recp.Recipient) {
        const nrp = new TempRecipients();
        nrp.RecListId = newr.RecListId;
        if (ocmnt === false) {
          nrp.Recipient = newr.Recipient;
          nrp.RecipientType = newr.RecipientType;
        } else {
          nrp.Recipient = recp.Recipient;
          nrp.RecipientType = recp.RecipientType;
        }
        nrp.RecipientComment = newr.RecipientComment;
        nrp.user = this.user;
        nrp.group = this.group;

        options = [];
        options.push({label: edt, value: 'edit',
        command: async (event) => { this.manageRecipient(event, nrp); }});
        options.push({label: del, value: 'delete',
        command: async (event) => { this.manageRecipient(event, nrp); }});

        nrp.options = options;
        nrs.push(nrp);
      } else {
        nrs.push(val);
      }
    }

    this.temprecipients = nrs;

    this.arrangeUsers();
    this.arrangeGroups();

    this.iseditrecipient = false;
  }

  async addRecipient() {
    let isadd = true;

    this.rtypeerror = false;
    this.recipterror = false;
    this.rcommenterror = false;
    this.invalidemail = false;
    this.invalidphone = false;

    if (this.rtype === undefined || this.rtype === null) {
      this.rtypeerror = true;
      isadd = false;

      if (this.recipt === undefined || this.recipt === null || this.recipt.trim() === '') {
        this.recipterror = true;
      }
    }
    if ((this.rtype === 'phone' || this.rtype === 'email') && this.recipt.trim() === '') {
      this.recipterror = true;
      isadd = false;
    } else if (this.rtype === 'email' && !this.recipt.match('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')) {
      this.invalidemail = true;
      isadd = false;
    } else if (this.rtype === 'phone' && !this.recipt.match('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')) {
      this.invalidphone = true;
      isadd = false;
    }
    if ((this.rtype === 'user' && (this.user === undefined || this.user === null))
    || (this.rtype === 'user-group' && (this.group === undefined || this.group === null))) {
      this.recipterror = true;
      isadd = false;
    }
    if (this.rcomment === undefined || this.rcomment === null || this.rcomment.trim() === '') {
      this.rcommenterror = true;
      isadd = false;
    }

    if (isadd === true) {
      const rp = new Recipients();
      if (this.rtype === 'user') {
        this.recipt = this.user.userId;
      }
      if (this.rtype === 'user-group') {
        this.recipt = this.group.groupId;
      }

      if (this.isedit === true) {
        rp.RecListId = this.rlist.recipientListId;
      } else {
        rp.RecListId = 0;
      }
      rp.Recipient = this.recipt;
      rp.RecipientComment = this.rcomment;
      rp.RecipientType = this.rtype;

      if (this.iscreate === true || this.isedit === true) {

        if (this.temprecipients !== null && this.temprecipients.length > 0) {
          for (const tval of this.temprecipients) {
            if (tval.RecipientType === rp.RecipientType && tval.Recipient === rp.Recipient) {
              return;
            }
          }
        }

        const rpp = new TempRecipients();
        rpp.RecListId = rp.RecListId;
        rpp.Recipient = rp.Recipient;
        rpp.RecipientComment = rp.RecipientComment;
        rpp.RecipientType = rp.RecipientType;
        rpp.user = this.user;
        rpp.group = this.group;

        const options = [];
        const edt = await this.translate.get('GENERAL.EDIT').toPromise();
        const del = await this.translate.get('GENERAL.DELETE').toPromise();
        options.push({label: edt, value: 'edit',
        command: async (event) => { this.manageRecipient(event, rpp); }});
        options.push({label: del, value: 'delete',
        command: async (event) => { this.manageRecipient(event, rpp); }});
        rpp.options = options;
        this.temprecipients.push(rpp);
        this.arrangeUsers();
        this.arrangeGroups();

        this.rtype = null;
        this.user = null;
        this.group = null;
        this.rcomment = null;
        this.recipt = null;

        return;
      }

      this.showLoad();

      const res = await this.recpService.postRecipient(rp).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res > 0) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RECIPIENTADDED').toPromise().catch();
        this.showSuccess(title, msg);

        this.cancelRecipient();

        await this.getRecipients();
      }

      this.hideLoad();
    }
  }

  async saveRecipient() {
    let isadd = true;

    this.rtypeerror = false;
    this.recipterror = false;
    this.rcommenterror = false;
    this.invalidemail = false;
    this.invalidphone = false;

    if (this.rtype === undefined || this.rtype === null) {
      this.rtypeerror = true;
      isadd = false;

      if (this.recipt === undefined || this.recipt === null || this.recipt.trim() === '') {
        this.recipterror = true;
      }
    }
    if ((this.rtype === 'phone' || this.rtype === 'email') && this.recipt.trim() === '') {
      this.recipterror = true;
      isadd = false;
    } else if (this.rtype === 'email' && !this.recipt.match('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')) {
      this.invalidemail = true;
      isadd = false;
    } else if (this.rtype === 'phone' && !this.recipt.match('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')) {
      this.invalidphone = true;
      isadd = false;
    }
    if ((this.rtype === 'user' && (this.user === undefined || this.user === null))
    || (this.rtype === 'user-group' && (this.group === undefined || this.group === null))) {
      this.recipterror = true;
      isadd = false;
    }
    if (this.rcomment === undefined || this.rcomment === null || this.rcomment.trim() === '') {
      this.rcommenterror = true;
      isadd = false;
    }

    if (isadd === true) {
      if (this.rtype === 'user') {
        this.recipt = this.user.userId;
      }
      if (this.rtype === 'user-group') {
        this.recipt = this.group.groupId;
      }

      const orp = new Recipients();
      if (this.isedit === true && this.iscreate === true) {
        orp.RecListId = this.recp.recListId;
        orp.Recipient = this.recp.recipient;
        orp.RecipientComment = this.recp.recipientComment;
        orp.RecipientType = this.recp.recipientType;
      } else {
        orp.RecListId = this.recp.RecListId;
        orp.Recipient = this.recp.Recipient;
        orp.RecipientComment = this.recp.RecipientComment;
        orp.RecipientType = this.recp.RecipientType;
      }

      const rp = new Recipients();
      if (this.isedit === true) {
        rp.RecListId = this.rlist.recipientListId;
      } else {
        rp.RecListId = 0;
      }
      rp.Recipient = this.recipt;
      rp.RecipientComment = this.rcomment;
      rp.RecipientType = this.rtype;

      const value = new RecipientUpdater();
      value.OldRecipient = orp;
      value.NewRecipient = rp;

      if (this.iscreate === true || this.isedit === true) {
        this.setOldRecipient(orp, rp);
        return;
      }

      this.showLoad();

      const res = await this.recpService.putRecipient(value).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res > 0) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RECIPIENTUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.cancelRecipient();

        await this.getRecipients();
      }

      this.hideLoad();
    }
  }

  onChange() {
    this.recipt = null;
  }

  async deleteRecipient() {
    this.showLoad();

    const rp = new Recipients();
    rp.RecListId = this.recp.recListId;
    rp.Recipient = this.recp.recipient;
    rp.RecipientComment = this.recp.recipientComment;
    rp.RecipientType = this.recp.recipientType;

    const res = await this.recpService.deleteRecipient(rp).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res > 0) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.RECIPIENTDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteRecipient();

      await this.getRecipients();
    }

    this.hideLoad();
  }

  async showConfirmDeleteRecipient() {
    const title = await this.translate.get('GENERAL.DELETERECIPIENT').toPromise();
    this.showConfirm(title + ' ' + '?',
    '', 'deleterecipient');
  }

  onRejectDeleteRecipient() {
    this.messageService.clear('deleterecipient');
  }

  cancel() {
    this.listname = null;
    this.listdesc = null;
    this.iscreate = false;
    this.isedit = false;

    if (this.rlist === undefined || this.rlist === null) {
      this.backClicked();
    }

    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  async showConfirmDeleteRecipientList() {
    const title = await this.translate.get('GENERAL.DELETE').toPromise();
    this.showConfirm(title + ' ' + this.rlist.recipientListName + '?',
    '', 'deleterlist');
  }

  async deleteRecipientList() {
    this.showLoad();

    const res = await this.listService.deleteRecipientList(this.rlist.recipientListId).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.RECIPIENTLISTDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteRecipientList();

      this.size = this.page * this.size;
      this.page = 1;
      await this.getRecipientList();
      this.size = 100;

      if (this.rlists != null && this.rlists.length > 0) {
        await this.listClicked(this.rlists[0]);
      }
    }

    this.hideLoad();
  }

  onRejectDeleteRecipientList() {
    this.messageService.clear('deleterlist');
  }

  async create() {
    let iscreate = true;
    this.listnameerror = false;
    this.listdescerror = false;
    if (this.listname === undefined || this.listname === null || this.listname.trim() === '') {
      this.listnameerror = true;
      iscreate = false;
    }
    if (this.listdesc === undefined || this.listdesc === null || this.listdesc.trim() === '') {
      this.listdescerror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      const ls = new RecipientList();
      ls.RecipientListName = this.listname;
      ls.RecipientListDescription = this.listdesc;

      const rgroups = [];
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'recipient_list';

          rgroups.push(rgroup);
        }
      }

      ls.RoleGroups = rgroups;
      ls.Recipients = this.temprecipients;

      this.showLoad();

      const res = await this.listService.postRecipientList(ls).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res > 0) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RECIPIENTLISTCREATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.page * this.size;
        this.page = 1;
        await this.getRecipientList();
        this.size = 100;

        if (this.rlists != null && this.rlists.length > 0) {
          for (const val of this.rlists) {
            if (val.recipientListId === res) {
              await this.listClicked(val);
            }
          }
        }

        this.cancel();
      }

      this.hideLoad();
    } else {
      setTimeout(() => {
        this.onResize(null);
      }, 100);
    }
  }

  async save() {
    let isedit = true;
    this.listnameerror = false;
    this.listdescerror = false;
    if (this.listname === undefined || this.listname === null || this.listname.trim() === '') {
      this.listnameerror = true;
      isedit = false;
    }
    if (this.listdesc === undefined || this.listdesc === null || this.listdesc.trim() === '') {
      this.listdescerror = true;
      isedit = false;
    }

    if (isedit === true) {
      const value = new Int64Updater();
      value.Id = this.rlist.recipientListId;
      value.Values = [];

      const name = new Keyvalue();
      name.Key = 'name';
      name.Value = this.listname;
      value.Values.push(name);

      const desc = new Keyvalue();
      desc.Key = 'desc';
      desc.Value = this.listdesc;
      value.Values.push(desc);

      const rgroups = [];
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.ParentId = this.rlist.recipientListId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'recipient_list';

          rgroups.push(rgroup);
        }
      }

      const data = new TypeRoleGroupData();
      data.ParentId = this.rlist.recipientListId;
      data.RoleParentType = 'recipient_list';
      data.TypeRoleGroup = rgroups;

      const rg = new KeyValue();
      rg.Key = 'role_groups';
      rg.Value = JSON.stringify(data);
      value.Values.push(rg);

      const rs = new Keyvalue();
      rs.Key = 'recipients';
      rs.Value = JSON.stringify(this.temprecipients);
      value.Values.push(rs);

      this.showLoad();

      const res = await this.listService.putRecipientList(value).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RECIPIENTLISTUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.page * this.size;
        this.page = 1;
        await this.getRecipientList();
        this.size = 100;

        if (this.rlists != null && this.rlists.length > 0) {
          for (const val of this.rlists) {
            if (val.recipientListId === this.rlist.recipientListId) {
              await this.listClicked(val);
            }
          }
        }

        this.cancel();
      }

      this.hideLoad();
    } else {
      setTimeout(() => {
        this.onResize(null);
      }, 100);
    }
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  onResize(event) {
    const menuheight = document.getElementById('menu')?.offsetHeight;
    const contentheight = window.innerHeight;

    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    this.innerdiv = (contentheight - 138) + 'px';
    this.outerdiv = '100%'

    const mheight = document.getElementById('maindata')?.offsetHeight;
    if (mheight !== undefined && mheight !== null) {
      this.innerinnerdiv = (contentheight - 138 - mheight - 10 - 50) + 'px';
    }

    if (this.iscreate === true || this.isedit === true) {
      this.innerinnerdiv = (contentheight - 138 - mheight - 10 - 100) + 'px';
      this.pickerdiv = (contentheight - 138 - mheight - 10 - 325) + 'px';
    }

    if ((this.isedit === true || this.iscreate === true) && window.innerHeight < 700) {
      this.outerdiv = (contentheight - menuheight - 75) + 'px';
      this.innerinnerdiv = (contentheight - 138 - mheight - 10 + 115) + 'px';
      this.pickerdiv = '200px';
    }
  }

  async handleError(err) {
    this.hideLoad();
    if (err !== undefined && err !== null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText === 'duplicate key value violates unique constraint "t_rec_list_rec_list_name_key"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise();
          const msg = await this.translate.get('GENERAL.RECIPIENTLISTALREADYEXISTS').toPromise();
          this.showWarn(title, msg, 10000);
        } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_recipient_rec_list_id_rec_type_recipient_key"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise();
          const msg = await this.translate.get('GENERAL.RECIPIENTALREADYEXISTS').toPromise();
          this.showWarn(title, msg, 10000);
        } else {
          console.log(err);
        }
      } else {
        console.log(err);
      }
    }
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // // apiUrl: 'http://169.254.80.80:3031',
  // apiUrl: 'https://localhost:44339',
  // appUrl: 'http://localhost:4204',
  // // usmApiUrl: 'https://localhost:44328',
  // usmApiUrl: 'http://169.254.80.80:3011',
  // flmApiUrl: 'http://localhost:3081',
  // portalApiUrl: 'http://169.254.80.80:3001',
  // realm: 'GoeplaRealm',
  // clientid: 'GoeplaClient',
  // keycloakUrl: 'http://localhost:8080'

  production: true,
  apiUrl: window["env"]["APIURL"] || "default",
  appUrl: window["env"]["APPURL"] || "default",
  usmApiUrl: window["env"]["USMAPIURL"] || "default",
  flmApiUrl: window["env"]["FLMAPIURL"] || "default",
  keycloakUrl: window["env"]["KEYCLOAKURL"] || "default",
  realm: window["env"]["REALM"] || "default",
  clientid: window["env"]["CLIENTID"] || "default",
  portalApiUrl: window["env"]["PORTALAPIURL"] || "default"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { RecipientList } from './../../Models/recipient-list';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Int64Updater, StringUpdater } from 'src/app/Models/keyvalue';

@Injectable({
  providedIn: 'root'
})
export class RecipientListService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRecipientLists(page, size, name) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/recipientlist/' + page + '/' + size + '/' + name + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRecipientList(rlist: RecipientList) {
    return this.httpclient.post(this.apiUrl + '/api/recipientlist', rlist)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putRecipientList(val: Int64Updater) {
    return this.httpclient.put(this.apiUrl + '/api/recipientlist/' + val.Id, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipientList(id: number) {
    return this.httpclient.delete(this.apiUrl + '/api/recipientlist/' + id)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

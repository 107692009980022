import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { Variables } from '../Models/variables';
import { Urls } from './Urls';
import * as CryptoJS from 'crypto-js';

export class DataCache {
    static groupdata$: Observable<any>;
    static userdata$: Observable<any>;

    static groupData: any;
    static userData: any;

    static url = new Urls();
    static http: any;

    constructor(private ht: HttpClient) {
        DataCache.http = ht;
    }

    static loadData(ht: HttpClient) {
        DataCache.http = ht;
        // const apiUrl = this.url.apiUrl
        const usmApiUrl = this.url.usmApiUrl;

        const v = new Variables();
        const val = localStorage.getItem('oi');
        if (val === undefined || val === null) {
          return;
        }
        const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

        const orgId = +oi;

        const page = 0;
        const size = 0;

        // get groups
        this.groupdata$ = this.http.get(usmApiUrl + '/api/groups/' + orgId).pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get users
        this.userdata$ = this.http.get(usmApiUrl + '/api/users/' + orgId).pipe(
          tap(),
          shareReplay(1),
          tap()
        );
    }

    getGroupData() {
      DataCache.groupdata$.subscribe(data => DataCache.groupData = data);
    }

    getUserData() {
      DataCache.userdata$.subscribe(data => DataCache.userData = data);
    }

    clearData() {
        DataCache.groupData = null;
        DataCache.userData = null;
    }
}
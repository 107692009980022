import { Template } from './../../Models/template';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { StringUpdater } from 'src/app/Models/keyvalue';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getTemplates(page, size, templatename) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/template/' + page + '/' + size + '/' + templatename + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postTemplate(template: Template) {
    return this.httpclient.post(this.apiUrl + '/api/template', template)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putTemplate(val: StringUpdater) {
    return this.httpclient.put(this.apiUrl + '/api/template/update', val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteTemplate(id: number) {
    return this.httpclient.delete(this.apiUrl + '/api/template/' + id)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
    this.apiUrl = this.url.usmApiUrl;
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

    this.orgId = +oi;
  }

  getUserPermissions(uid, gid, perm) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userpermission/' + this.orgId + '/' + uid + '/' + gid + '/' + perm + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }

}

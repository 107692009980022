import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.css']
})
export class BrowserComponent implements OnInit {

  infoText = 'Information';
  closeText = 'Close';

  constructor(
    private confirmationService: ConfirmationService,
    private keycloakService: KeycloakService
  ) {
    const msg = localStorage.getItem('message');
    const info = localStorage.getItem('info');
    const but = localStorage.getItem('but');
    this.closeText = but;
    this.infoText = info;
    // this.confirm(msg);
   }

  ngOnInit() {
    const msg = localStorage.getItem('message');
    this.confirm(msg);
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
            if (this.keycloakService !== undefined && this.keycloakService !== null
              && this.keycloakService.getKeycloakInstance() !== undefined
              && this.keycloakService.getKeycloakInstance() !== null) {
              this.keycloakService.logout();
            }
        },
        reject: () => {
          if (this.keycloakService !== undefined && this.keycloakService !== null
            && this.keycloakService.getKeycloakInstance() !== undefined
            && this.keycloakService.getKeycloakInstance() !== null) {
            this.keycloakService.logout();
          }
        }
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Int64Updater } from 'src/app/Models/keyvalue';
import { RecipientList } from 'src/app/Models/recipient-list';
import { Recipients, RecipientUpdater } from 'src/app/Models/recipients';

@Injectable({
  providedIn: 'root'
})
export class RecipientService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRecipients(rlistid, rlistname, rtype) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/recipient/' + rlistid + '/' + rlistname + '/' + rtype + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRecipient(recp: Recipients) {
    return this.httpclient.post(this.apiUrl + '/api/recipient', recp)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putRecipient(val: RecipientUpdater) {
    return this.httpclient.put(this.apiUrl + '/api/recipient/0', val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRecipient(val: Recipients) {
    return this.httpclient.put(this.apiUrl + '/api/recipient/delete/0', val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

import { Notification } from './../../Helpers/notification';
import { PostmanMessageService } from './../../Services/postman-message/postman-message.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-postman-messages',
  templateUrl: './postman-messages.component.html',
  styleUrls: ['./postman-messages.component.css']
})
export class PostmanMessagesComponent implements OnInit {

  isVis = false;
  isLoad: boolean;

  messages = [];
  message: any;

  page = 1;
  size = 100;
  pager = 0;

  id: number;
  type: string;
  source: string;
  channel: string;
  start: string;
  end: string;
  notId: string;

  isLoadMore: boolean;

  invalidDate = false;

  date1 = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  selected: string;
  Filter: string;

  isNone: boolean;
  isPostMsgId: boolean;
  isSource: boolean;
  isChannel: boolean;
  isTarget: boolean;
  isRange: boolean;

  notifications: Notification[] = [];

  options = [];

  filters = [];
  filter: any;

  channels = [];
  chan: any;

  sources = [];
  src: any;
  range: any;

  constructor(
    private msgs: PostmanMessageService,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTIFICATIONID').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SOURCE').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CHANNEL').subscribe((resp4: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CREATEDRANGE').subscribe((resp5: string) => {
              this.filters = [
                { label: resp1, value: 'none' },
                { label: resp2, value: 'postman_msg_id' },
                { label: resp3, value: 'source' },
                { label: resp4, value: 'channel' },
                { label: resp5, value: 'time' }
              ];
              this.filter = this.filters[0].value;
            });
          });
        });
      });
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.MQTT').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMAIL').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SMS').subscribe((resp3: string) => {
          this.channels = [
            { label: resp1, value: 'M' },
            { label: resp2, value: 'E' },
            { label: resp3, value: 'S' }
          ];
        });
      });
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NTM').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.RTM').subscribe((resp2: string) => {
        this.sources = [
          { label: resp1, value: 'NTM' },
          { label: resp2, value: 'RTM' }
        ];
      });
    });

    this.selected = 'none';
    this.isNone = true;
    this.isSource = false;
    this.isChannel = false;
    this.isTarget = false;
    this.isRange = false;

    this.id = 0;
    this.source = ' ';
    this.channel = ' ';
    this.type = ' ';
    this.start = ' ';
    this.end = ' ';

    if (localStorage.getItem('selected') === null) {

    } else {
      const sel = localStorage.getItem('selected');
      const filt = localStorage.getItem('filter');

      this.filters = [];
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOTIFICATIONID').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SOURCE').subscribe((resp3: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CHANNEL').subscribe((resp4: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.CREATEDRANGE').subscribe((resp5: string) => {
                this.filters = [
                  { label: resp1, value: 'none' },
                  { label: resp2, value: 'postman_msg_id' },
                  { label: resp3, value: 'source' },
                  { label: resp4, value: 'channel' },
                  { label: resp5, value: 'time' }
                ];
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.filters.length; i++) {
                  if (this.filters[i].value === sel) {
                    this.filter = this.filters[i].value;
                  }
                }
              });
            });
          });
        });
      });

      this.selected = sel;
      if (sel === 'none') {
        this.isNone = true;
        this.isPostMsgId = false;
        this.isSource = false;
        this.isChannel = false;
        this.isTarget = false;
        this.isRange = false;
      } else if (sel === 'source') {
        this.source = filt;
        this.isTarget = false;
        this.isNone = false;
        this.isSource = true;
        this.isChannel = false;
        this.isRange = false;
      } else if (sel === 'channel') {
        this.channel = filt;
        this.isTarget = false;
        this.isNone = false;
        this.isSource = false;
        this.isChannel = true;
        this.isRange = false;
      } else if (sel === 'time') {
        const split = filt.split(';');
        this.start = split[0];
        this.end = split[1];

        this.date1.setValue(new Date(this.start));
        this.date2.setValue(new Date(this.end));

        this.range = [new Date(this.start), new Date(this.end)];

        this.isRange = true;
        this.isTarget = false;
        this.isPostMsgId = false;
        this.isNone = false;
        this.isSource = false;
        this.isChannel = false;
      }
    }

    this.getMessages();
   }

   ngOnInit() {
  }

  show(message) {
    this.message = message;
    this.isVis = true;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.messages.length; i++) {
      if (this.messages[i].id === message.id) {
        this.messages[i].color = 'lightgray';
      } else {
        this.messages[i].color = 'transparent';
      }
    }
  }

  getMessages() {
    this.showLoad();
    this.isLoad = true;
    this.msgs.getMessages(this.page, this.size, this.id,
      // tslint:disable-next-line: deprecation
       this.source, this.channel, this.start, this.end).subscribe((res) => {
         if (res == null) {
           // no messages
           this.isLoadMore = false;
           // tslint:disable-next-line: deprecation
           this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOMESSAGES').subscribe((resp2: string) => {
              // this.notificationSvc.info('', resp, 2000);
              this.showInfo2(resp, resp2);
              });
            });
         } else {
           // tslint:disable-next-line: prefer-for-of
           for (let i = 0; i < res.length; i++) {
             res[i].color = 'transparent';
             if (res[i].channel === 'M') {
              res[i].chan = 'MQTT';
             } else if (res[i].channel === 'S') {
              res[i].chan = 'SMS';
             } else if (res[i].channel === 'E') {
              res[i].chan = 'Email';
             }
             if (res[i].receivedConfirmation === '0001-01-01T00:00:00' || res[i].receivedConfirmation === null) {
              res[i].receivedConfirmation = '';
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOTRECEIVED').subscribe((resp: string) => {
                res[i].notReceived = resp;
                });
            } else {
              // res[i].receivedConfirmation = new Date(res[i].receivedConfirmation).toLocaleString();
              res[i].receivedConfirmation = new Date(res[i].receivedConfirmation.toString().replace('T', ' ') + ' UTC').toLocaleString();
              res[i].notReceived = '';
            }
             res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString();
             // res[i].created = new Date(res[i].created).toLocaleString();
    // tslint:disable-next-line: deprecation
             this.translate.get('GENERAL.DETAILS').subscribe((resp) => {
               this.options = [];
               this.options.push({label: resp, value: 'Details'});
               res[i].options = this.options;
             });
             this.messages.push(res[i]);
           }

           if (res.length < this.size) {
             this.isLoadMore = false;
           } else {
             this.isLoadMore = true;
           }
         }
         this.isLoad = false;
         this.hideLoad();
       }, err => this.handleError(err));
  }

  loadMore() {
    if (this.isPostMsgId === true || this.isSource === true || this.isChannel === true || this.isRange === true) {
        this.pager += 1;
        this.load();
    } else {
      this.page += 1;
      this.getMessages();
    }
  }

  selectedFilter(event) {
    this.selected = event.value;
    this.pager = 1;
    if (this.selected === 'none') {
      this.isNone = true;
      this.isPostMsgId = false;
      this.isSource = false;
      this.isChannel = false;
      this.isTarget = false;
      this.isRange = false;
    } else if (this.selected === 'postman_msg_id') {
      this.isPostMsgId = true;
      this.isNone = false;
      this.isSource = false;
      this.isChannel = false;
      this.isTarget = false;
      this.isRange = false;
    } else if (this.selected === 'source') {
      this.isPostMsgId = false;
      this.isNone = false;
      this.isSource = true;
      this.isChannel = false;
      this.isTarget = false;
      this.isRange = false;
    } else if (this.selected === 'channel') {
      this.isPostMsgId = false;
      this.isNone = false;
      this.isSource = false;
      this.isChannel = true;
      this.isTarget = false;
      this.isRange = false;
    } else if (this.selected === 'target_type') {
      this.isTarget = true;
      this.isPostMsgId = false;
      this.isSource = false;
      this.isChannel = false;
      this.isNone = false;
      this.isRange = false;
    } else if (this.selected === 'time') {
      this.start = this.date1.value.toDateString();
      this.end = this.date2.value.toDateString();

      this.isRange = true;
      this.isTarget = false;
      this.isPostMsgId = false;
      this.isSource = false;
      this.isChannel = false;
      this.isNone = false;
    }
  }

  ok() {
    this.isVis = false;
    localStorage.setItem('selected', this.selected);
    const check = true;
    if (check !== true) {

    } else {
      if (this.isNone === true) {
        this.id = 0;
        this.source = ' ';
        this.channel = ' ';
        this.start = ' ';
        this.end = ' ';
        localStorage.setItem('filter', '');
      } else if (this.isPostMsgId === true) {
        if (this.notId === undefined || this.notId === null || this.notId.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERNOTIFICATIONID').subscribe((res) => {
            this.confirm(res);
          });
        } else {
          this.messages = [];
          // tslint:disable-next-line: deprecation
          this.msgs.getMessageById(this.notId).subscribe((res) => {
            this.isLoad = false;
            this.isLoadMore = false;
            if (res.notificationId === null) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOMESSAGESFOUND').subscribe((resp: string) => {
                this.notificationSvc.info('', resp, 2000);
              });
            } else {
              res.color = 'transparent';
              if (res.channel === 'M') {
              res.chan = 'MQTT';
              } else if (res.channel === 'S') {
              res.chan = 'SMS';
              } else if (res.channel === 'E') {
              res.chan = 'Email';
              }
              if (res.receivedConfirmation === '0001-01-01T00:00:00' || res.receivedConfirmation === null) {
              res.receivedConfirmation = '';
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOTRECEIVED').subscribe((resp: string) => {
                res.notReceived = resp;
                });
            } else {
              // res.receivedConfirmation = new Date(res.receivedConfirmation).toLocaleString();
              res.receivedConfirmation = new Date(res.receivedConfirmation.toString().replace('T', ' ') + ' UTC').toLocaleString();
              res.notReceived = '';
            }
              res.created = new Date(res.created.toString().replace('T', ' ') + ' UTC').toLocaleString();
              // res.created = new Date(res.created).toLocaleString();
    // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DETAILS').subscribe((resp) => {
                this.options = [];
                this.options.push({label: resp, value: 'Details'});
                res.options = this.options;
              });
              this.messages.push(res);
            }
          });
        }
        return;
      } else if (this.isSource === true) {
        if (this.source === null || this.source === ' ' || this.source === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTSOURCE').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.id = 0;
          this.channel = ' ';
          this.start = ' ';
          this.end = ' ';
          localStorage.setItem('filter', this.source);
        }
      } else if (this.isChannel === true) {
        if (this.channel === null || this.channel === ' ' || this.channel === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTCHANNEL').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.id = 0;
          this.source = ' ';
          this.start = ' ';
          this.end = ' ';
          localStorage.setItem('filter', this.channel);
        }
      } else if (this.isRange === true) {
        // this.start = this.date1.value.toDateString();
        // this.end = this.date2.value.toDateString();
        if (this.range === undefined || this.range === null) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          if (this.range[0] !== undefined && this.range[0] !== null) {
            this.start = this.range[0].toDateString();
          } else {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
          if (this.range[1] !== undefined && this.range[1] !== null) {
            this.end = this.range[1].toDateString();
          } else {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
        }

        if (this.start === ' ' || this.start === '' || this.end === ' ' || this.end === '') {
          return;
        } else {
            if (this.invalidDate === true) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INVALIDDATEFORMAT').subscribe((resp: string) => {
                alert(resp);
                });
              return;
          } else {
              if (new Date(this.start) > new Date(this.end)) {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.ENDDATEGREATER').subscribe((resp: string) => {
                  alert(resp);
                  });
                return;
            } else {
              this.id = 0;
              this.source = ' ';
              this.channel = ' ';
              localStorage.setItem('filter', this.start + ';' + this.end);
            }
          }
        }
      }

      this.showLoad();
      this.isLoad = true;
      this.page = 1;
      // tslint:disable-next-line: deprecation
      this.msgs.getMessages(this.page, this.size, this.id, this.source, this.channel, this.start, this.end).subscribe((res) => {
          this.messages = [];
          if (res == null) {
            this.isLoad = false;
            this.isLoadMore = false;
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOMESSAGES').subscribe((resp2: string) => {
                // this.notificationSvc.info('', resp, 2000);
                this.showInfo2(resp, resp2);
              });
            });
          } else {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < res.length; i++) {
              res[i].color = 'transparent';
              if (res[i].channel === 'M') {
              res[i].chan = 'MQTT';
              } else if (res[i].channel === 'S') {
              res[i].chan = 'SMS';
              } else if (res[i].channel === 'E') {
              res[i].chan = 'Email';
              }
              if (res[i].receivedConfirmation === '0001-01-01T00:00:00'  || res[i].receivedConfirmation === null) {
              res[i].receivedConfirmation = '';
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOTRECEIVED').subscribe((resp: string) => {
                res[i].notReceived = resp;
                });
            } else {
              // res[i].receivedConfirmation = new Date(res[i].receivedConfirmation).toLocaleString();
              res[i].receivedConfirmation = new Date(res[i].receivedConfirmation.toString().replace('T', ' ') + ' UTC').toLocaleString();
              res[i].notReceived = '';
            }
              res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString();
              // res[i].created = new Date(res[i].created).toLocaleString();
    // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DETAILS').subscribe((resp) => {
                this.options = [];
                this.options.push({label: resp, value: 'Details'});
                res[i].options = this.options;
              });
              this.messages.push(res[i]);
            }

            if (res.length < this.size) {
              this.isLoadMore = false;
            } else {
              this.isLoadMore = true;
            }
          }
          this.isLoad = false;
          this.hideLoad();
        });
    }
  }

  load() {
    if (this.isNone === true) {
      this.id = 0;
      this.source = ' ';
      this.channel = ' ';
      this.start = ' ';
      this.end = ' ';
    } else if (this.isSource === true) {
      if (this.source === ' ' || this.source === '' || this.source === null) {
        return;
      } else {
        this.id = 0;
        this.channel = ' ';
        this.start = ' ';
        this.end = ' ';
      }
    } else if (this.isChannel === true) {
      if (this.channel === ' ' || this.channel === '' || this.channel === null) {
        return;
      } else {
        this.id = 0;
        this.source = ' ';
        this.start = ' ';
        this.end = ' ';
      }
    } else if (this.isRange === true) {
        // this.start = this.date1.value.toDateString();
        // this.end = this.date2.value.toDateString();
        if (this.start === ' ' || this.start === '' || this.end === ' ' || this.end === '') {
          return;
        } else {
          this.id = 0;
          this.source = ' ';
          this.channel = ' ';
        }
      }

    // tslint:disable-next-line: deprecation
    this.msgs.getMessages(this.pager, this.size, this.id, this.source, this.channel, this.start, this.end).subscribe((res) => {
        if (res == null) {
          this.isLoad = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOMESSAGES').subscribe((resp2: string) => {
              // this.notificationSvc.info('', resp, 2000);
              this.showInfo2(resp, resp2);
            });
          });
        } else {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            res[i].color = 'transparent';
            if (res[i].channel === 'M') {
             res[i].chan = 'MQTT';
            } else if (res[i].channel === 'S') {
             res[i].chan = 'SMS';
            } else if (res[i].channel === 'E') {
             res[i].chan = 'Email';
            }
            if (res[i].receivedConfirmation === '0001-01-01T00:00:00' || res[i].receivedConfirmation === null) {
             res[i].receivedConfirmation = '';
             // tslint:disable-next-line: deprecation
             this.translate.get('GENERAL.NOTRECEIVED').subscribe((resp: string) => {
               res[i].notReceived = resp;
               });
           } else {
             // res[i].receivedConfirmation = new Date(res[i].receivedConfirmation).toLocaleString();
             res[i].receivedConfirmation = new Date(res[i].receivedConfirmation.toString().replace('T', ' ') + ' UTC').toLocaleString();
             res[i].notReceived = '';
           }
            res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString();
            // res[i].created = new Date(res[i].created).toLocaleString();
    // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DETAILS').subscribe((resp) => {
              this.options = [];
              this.options.push({label: resp, value: 'Details'});
              res[i].options = this.options;
            });
            this.messages.push(res[i]);
          }

          if (res.length < this.size) {
            this.isLoadMore = false;
          } else {
            this.isLoadMore = true;
          }
        }
      });
  }

  // addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
  //   if (event.value === null) {
  //     this.invalidDate = true;
  //   } else {
  //     this.invalidDate = false;
  //     this.start = event.value.toDateString();
  //   }
  // }

  // addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
  //   if (event.value === null) {
  //     this.invalidDate = true;
  //   } else {
  //     this.invalidDate = false;
  //     this.end = event.value.toDateString();
  //   }
  // }

  saveMessage(event, message) {
    localStorage.setItem('message', JSON.stringify(message));
    if (event.value === 'Details') {
      this.router.navigateByUrl('/messagedetail/' + message.notificationId);
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo2(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  handleError(err) {
    this.isLoad = false;
    this.hideLoad();
    console.log(err);
  }

}

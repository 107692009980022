export class Recipients {
    public RecListId: number;
    public RecipientType: string;
    public Recipient: string;
    public RecipientComment: string;
}

export class RecipientUpdater {
    public OldRecipient: Recipients;
    public NewRecipient: Recipients;
}

export class TempRecipients {
    public RecListId: number;
    public RecipientType: string;
    public Recipient: string;
    public RecipientComment: string;
    public options: any[];
    public group: any;
    public user: any;
}

import { Recipients } from 'src/app/Models/recipients';
import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class RecipientList {
    public RecipientListId: number;
    public RecipientListName: string;
    public RecipientListDescription: string;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
    public Recipients: Recipients[];
}
